import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/sign',
        name: 'Sign',
        component: () => import('@/views/Sign.vue'),
        meta: {
            title: "注册"
        }
    },
    {
        path: '/resetPass',
        name: 'ResetPass',
        component: () => import('@/views/ResetPass.vue'),
        meta: {
            title: "重置密码"
        }
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/Contact.vue'),
        meta: {
            title: "联系我们"
        }
    },
    {
        path: '/user',
        name: 'User',
        component: () => import('@/views/User.vue'),
        meta: {
            title: "个人资料"
        }
    },
    {
        path: '/topic',
        name: 'Topic',
        component: () => import('@/views/Topic.vue'),
        meta: {
            title: "..."
        }
    },
    {
        path: '/search',
        name: 'Search',
        component: () => import('@/views/Search.vue'),
        meta: {
            title: "搜索"
        }
    },
    {
        path: '/richText',
        name: 'RichText',
        component: () => import('@/views/RichText.vue'),
        meta: {
            title: "帮助"
        }
    },
    {
        path: '/columnSearch',
        name: 'ColumnSearch',
        component: () => import('@/views/ColumnSearch.vue'),
        meta: {
            title: "栏目文章"
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})
router.beforeEach(function (to, from, next) {
    if (!store.state.userInfo) {
        store.commit("setUserInfo", JSON.parse(localStorage.getItem("webUserInfo")));
    }
    window.scroll(0, 0);
    next()
})
export default router