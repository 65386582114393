import {
    get,
    post
} from './http'
//服务条款
export const getService = () => post('/pc/homePage/getMessage', null, {
    type: 0
});
//隐私声明
export const getPersonalInfo = () => post('/pc/homePage/getMessage', null, {
    type: 1
});
//网站简介
export const getWebIntro = () => post('/pc/homePage/getMessage', null, {
    type: 2
});
//帮助
export const getHelp = () => post('/pc/homePage/getMessage', null, {
    type: 3
});
//首页滚动文字
export const getRollText = () => post('/pc/homePage/getMessage', null, {
    type: 4
});
//首页数据
export const getHomepage = (data) => post('/pc/homePage/selectHomePage', data);

//首页广告
export const getHomeAdv = (data) => post('/picture/page/list', data);

//首页栏目导航
export const getHomeColumns = (data) => post('/pc/homePage/selectHomePageColumnNameList', data);
//首页栏目列表
export const getHomeColumnsList = (data) => post('/pc/homePage/selectHomePageColumnNameListLB', data);