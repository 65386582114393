<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
    export default {

    }
</script>
<style lang="scss">
    #app {
        font-weight: 400;
        color: #333;
        font-size: 12px;
    }

    .container {
        width: 100%;
        min-width: 1300px;
        overflow: hidden;
        position: relative;
        z-index: 0;
    }

    html,
    body {
        margin: 0;
        padding: 0;
        background-color: #f3f9ff;
    }

    .hide {
        display: none;
    }

    input {
        outline: none;
        border: none;
    }

    input::-webkit-search-cancel-button {
        display: none;
    }

    input[type=search]::-ms-clear {
        display: none;
    }
</style>