import {
    get,
    post
} from './http'
//检测登录状态
export const isLogin = data => get('/pc/homePage/isLogin', data)
//注册
export const register = data => post('/pc/signUp/register', data)
//登录
export const login = data => post('/login', data)
//退出登录
export const loginOut = data => post('/logout', data)
//找回密码(不知道账号的情况下)
export const findAccount = (data, params) => post('/pc/signUp/getUserName', data, params)


//重置密码-账号手机号校验
export const resetPass = data => post('/pc/signUp/resetPassword', data)
//重置密码-设置新密码
export const resetUpdatePassword = data => post('/pc/signUp/resetUpdatePassword', data)
//修改密码
export const updatePass = data => post('/pc/signUp/updatePassword', data)

//修改信息
export const updateInfo = data => post('/pc/signUp/updateUserData', data)
//发送验证码
export const sendCode = (data, params) => post('/pc/signUp/verificationCode', data, params)
//查看详情
export const getDetail = (data, params) => post('/user/queryById', data, params)