import Vue from 'vue'
import Vuex from 'vuex'
import {
    setCookie,
    getCookie,
    delCookie
} from "@/utils/cookieManage";
import {
    login,
    loginOut
} from "@/request/user";
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: null,
        loginInfo: undefined,
        loginTime: undefined,
    },
    getters: {
        getWebUserInfo(state) {
            let localUserInfo = localStorage.getItem("webUserInfo");
            if (localUserInfo) {
                localUserInfo = JSON.parse(localUserInfo);
            }
            return state.userInfo || localUserInfo;
        },
        getLoginInfo(state) {
            let localLoginInfo = localStorage.getItem("loginInfo");
            if (localLoginInfo) {
                localLoginInfo = JSON.parse(localLoginInfo);
            }
            return state.loginInfo || localLoginInfo;
        },
        getHasLogin(state) {
            return state.loginTime || localStorage.getItem("loginTime");
        }

    },
    mutations: {
        //记录登录事件
        setLoginTime(state, data) {
            state.loginTime = data;
            localStorage.setItem("loginTime", data);
        },
        //登录方法
        store_login(state, data) {
            console.log(data)
            login({
                username: data.userName,
                password: data.password,
                captcha: data.userVaildcode
            }).then(res => {
                if (!res.error) {
                    this.commit("setLoginTime", new Date().getTime()); //时间戳
                    this.commit("setUserInfo", res.data); //更新用户信息
                    if (data.keepNamePass) { //保存登录信息
                        this.commit("store_loginInfo", {
                            username: data.userName,
                            password: data.password
                        })
                    } else {
                        this.commit("store_loginInfo", undefined)
                    }
                    data.callbackFun();
                }
            })
        },
        //退出登录
        store_loginOut(state, data) {
            loginOut({}).then(res => {
                this.commit("setUserInfo", null); //更新用户信息
                data.callbackFun();
            })
        },
        //登录表单保存（自动置入输入框）
        store_loginInfo(state, data) {
            state.loginInfo = data;
            if (data) {
                localStorage.setItem("loginInfo", JSON.stringify(data))
            } else {
                localStorage.setItem("loginInfo", undefined)
            }
        },
        //更新用户个人信息
        setUserInfo(state, data) {
            state.userInfo = data;
            localStorage.setItem("webUserInfo", JSON.stringify(data));
        },
        removeUserInfo(state) {
            console.log("清空了用户信息")
            state.userInfo = undefined;
            localStorage.removeItem("webUserInfo");
        }
    },
    actions: {},
    modules: {}
})