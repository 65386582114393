import {
    Pagination,
    MessageBox,
    Message,
    Notification,
    DatePicker,
    Loading
} from 'element-ui';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// import './lib/lib-flexible'
// import 'amfe-flexible'
import {
    post,
    get,
    patch,
    put
} from './request/http'
//seo
import MetaInfo from 'vue-meta-info';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)



Vue.use(MetaInfo)


Vue.component(Pagination.name, Pagination);

Vue.component(DatePicker.name, DatePicker);
Vue.component(Message.name, Message);
Vue.component(MessageBox.name, MessageBox);
Vue.component(Notification.name, Notification);
Vue.use(Loading.directive);




//定义全局变量
Vue.prototype.$message = Message;
Vue.prototype.$messageBox = MessageBox;
Vue.prototype.$notification = Notification;
Vue.prototype.$Loading = Loading;
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$patch = patch;
Vue.prototype.$put = put;
Vue.config.productionTip = false;
//路由发生改变时,修改标题
router.beforeEach((to, from, next) => {
    if (to.meta.title == "栏目文章") {
        if (to.query.managementName) {
            window.document.title = to.query.managementName;
        }
    } else {
        window.document.title = to.meta.title == undefined ? '朴谷网-工程信息行业高端品牌-河北朴谷网络科技有限公司' : to.meta.title
    }
    next();
})
//百度统计
router.afterEach((to, from) => {
    // 每次进入路由都会触发
    if (window._hmt) {
        if (to.path) {
            window._hmt.push(['_setAutoPageview', false]);
            window._hmt.push(['_trackPageview', '/template/displayTemplate/dist/index.html#' + to.fullPath]); // 如果不是根路径，需要指定第二个参数的路径
        }
    }
});

Vue.mixin({
    beforeCreate() {
        const options = this.$options;
        this.$hm = options.parent && options.parent.$hm ?
            options.parent.$hm :
            (function (window) {
                if (!window._hmt) {
                    return;
                }
                return Object.assign({}, window._hmt, { // 统计页面的点击事件
                    click: (category, val) => {
                        const userInfo = store.getters.userInfo || {};
                        return window._hmt.push([
                            '_trackEvent',
                            category,
                            'click',
                            userInfo.loginName || 'notLogin',
                            val
                        ]);
                    }
                });
            })(window);
    }
});
let startApp = function () {
    let basicUrl = '/basic.json'
    // let basicUrl = '../ZTB-web/basic.json'
    axios.get(basicUrl).then((res) => {
        // 基础地址
        axios.defaults.baseURL = res.data.BASE_URL;
        Vue.prototype.$RESOURCE_URL = res.data.RESOURCE_URL;
        new Vue({
            router,
            store,
            render: h => h(App),
            mounted() {
                // document.dispatchEvent(new Event('render-event'))
            }
        }).$mount("#app")
    })
}
startApp()
