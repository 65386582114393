import axios from 'axios';
import Vue from 'vue'
import store from '../store'
axios.defaults.timeout = 10000;
axios.defaults.baseURL = '';
axios.defaults.withCredentials = true;
import {
    Pagination,
    MessageBox,
    Message,
    Notification,
    DatePicker
} from 'element-ui';
//http request 拦截器
axios.interceptors.request.use(
    config => {
        config.headers = {
            'Content-Type': 'application/json',
            'userType': '1'
        }
        // let userInfo = sessionStorage.getItem("userInfo");
        // console.log("userInfo : " + userInfo)
        // console.log("userInfo != null" + " : " + (userInfo != null))
        // let sessionId = null;
        // if (userInfo != null) {
        //     sessionId = JSON.parse(userInfo).sessionId;
        //     config.headers.sessionId = sessionId;
        // }
        config.data = JSON.stringify(config.data);
        return config;
    },
    error => {
        return Promise.reject(err);
    }
);

function getCookie(name) {
    var reg = RegExp(name + '=([^;]+)');
    var arr = document.cookie.match(reg);
    if (arr) {
        return arr[1];
    } else {
        return '';
    }
}
//http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.code && response.data.code != 200 || response.data.error) {
            if (response.data.code === "0001") { //未登录
                // Message.error("登录已过期，请重新登录");
                store.commit("removeUserInfo");
            }
            // else if (getCookie('account') !== "null" && getCookie('pwd') !== "null" && response.data.code === 400) { //保存账号密码的情况下，登录验证失败
            //     // console.log(getCookie('account'), getCookie('pwd'))
            //     // Message.error("保存的账户密码已过期，请重新登录");
            //     //清空保存的账号信息和用户信息
            //     store.commit("removeUserInfo");
            // }
            else {
                if (response.data) {
                    let msg = response.data.msg ? response.data.msg : response.data.message;
                    Message.error(msg)
                }
            }

        }
        return response;
    },
    error => {
        return Promise.reject(error)
    }
)

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
                params: params
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function post(url, data = {}, params = undefined) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
                params: params
            })
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}