<template>
    <div class='container'>
        <div class="nav">
            <div class="navItem">
                <img class="navItemIcon" src="@/assets/img/slideNav1.png" alt="">
                <div class="hoverBox">
                    <span>微信扫一扫</span>
                    <img :src="$RESOURCE_URL+contactData.gzhRqCode" alt="">
                </div>
            </div>
            <div class="navItem">
                <img class="navItemIcon" src="@/assets/img/slideNav2.png" alt="">
                <div class="hoverBox">
                    <span>联系客服</span>
                    <img :src="$RESOURCE_URL+contactData.kfRqCode" alt="">
                </div>
            </div>
            <div class="navItem" @click="scrollTop">
                <img class="navItemIcon" src="@/assets/img/slideNav3.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import {
        contactUsList
    } from "@/request/contact"
    export default {
        name: 'SlideFixNav',
        props: {},
        data() {
            return {
                contactData: {}
            };
        },
        mounted() {
            this.getBasicInfo()
        },
        methods: {
            getBasicInfo() {
                contactUsList({}).then(res => {
                    this.contactData = res.data[0]
                })
            },
            scrollTop() {
                window.scroll(0, 0);
            }
        },
    };
</script>

<style scoped lang="scss">
    .container {
        z-index: 3;
    }

    .nav {
        position: fixed;
        z-index: 3;
        right: 2%;
        top: 40%;
        width: 50px;
        height: 170px;

        .navItem {
            width: 50px;
            height: 50px;
            position: relative;
            z-index: -1;
            margin-bottom: 10px;
            cursor: pointer;

            .navItemIcon {
                display: block;
                width: 50px;
                height: 50px;
            }

            .hoverBox {
                position: absolute;
                left: -220px;
                top: 0px;
                z-index: -1;
                width: 200px;
                box-sizing: border-box;
                background-color: #fff;
                box-shadow: 5px 5px 8px 0 #000;
                font-size: 14px;
                border-radius: 2px;
                height: 0px;
                padding: 0px;
                opacity: 0;
                overflow: hidden;
                transition: all 500ms;

                span {
                    text-align: center;
                    display: block;
                    margin-bottom: 10px;
                }

                img {
                    width: 150px;
                    height: 150px;
                    display: block;
                    margin: 0 auto;
                }
            }
        }

        .navItem:hover {
            .hoverBox {
                padding: 25px;
                padding-top: 10px;
                opacity: 1;
                height: 200px;
            }

        }
    }
</style>