<template>
    <div class='container'>
        <div class="fixBox">
            <div class="inner">
                <!-- logo、search -->
                <div class="searchBlock">
                    <a href="https://www.puguweb.com/" class="logo"></a>
                    <!-- search -->
                    <div class="searchBox" v-if="keepSearchShow||showSearch">
                        <!-- areas -->
                        <!-- input -->
                        <input type="text" placeholder="搜索名称 / 关键词" v-model="keyword"
                            @keyup.enter="goSearchKeyWord(keyword)" maxlength="50" />
                        <!-- searchIcon -->
                        <img src="@/assets/img/searchIcon.png" alt="" class="searchIcon"
                            @click="goSearchKeyWord(keyword)">
                    </div>

                </div>
                <!-- columns/login/sign -->
                <div class="columnsBlock">
                    <!-- columns -->
                    <div class="columns">
                        <span @click="goIndex()">首页</span>
                        <span v-for="item in columns" :key="item.id" @click="goColumnSearch(item)">{{item.name}}</span>
                    </div>
                    <!-- login、sign -->
                    <div class="unLogin" v-if="!userInfo">
                        <span class="login" @click="openLoginDialog">登录</span>
                        <span style="text-decoration: none;">/</span>
                        <span class="sign" @click="goSign">注册</span>
                    </div>
                    <div class="hasLogin" v-if="userInfo">
                        <!-- {{userInfo.account}} -->
                        <span class="login" @click="goUser">{{userInfo.account}}</span>
                        <span class="loginOut" @click="loginOut">退出登录</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="loginDialog" v-if="showLoginDialog&&!userInfo">
            <div class="fixContainer">
                <div class="loginBox">
                    <img src="@/assets/img/headerLoginBg.png" alt="">
                    <div class="loginBlock">
                        <div class="closeLogin" @click="closeLoginDialog"><img src="@/assets/img/closeLogin.png" alt="">
                        </div>
                        <div class="tit">登录/注册</div>
                        <div class="row accountRow">
                            <input type="text" maxlength="20" v-model="userName" placeholder="请输入账户"
                                @keyup.enter="btn_login">
                        </div>
                        <div class="row passRow">
                            <input type="password" maxlength="20" v-model="password" placeholder="请输入密码"
                                @keyup.enter="btn_login" />
                        </div>
                        <div class="row verfRow">
                            <input type="text" maxlength="6" v-model="userVaildcode" placeholder="请输入验证码"
                                @keyup.enter="btn_login" />
                            <div class="vaildBox" @click="changeVaildCode">
                                <SIdentify :contentWidth="73" :contentHeight="32" :identifyCode="identifyCode" />
                            </div>
                        </div>
                        <div class="btns">
                            <div class="savePass">
                                <input type="checkbox" v-model="savePass" />
                                <span>记住密码</span>
                            </div>
                            <div class="goRegister" @click="goSign">
                                注册
                            </div>
                            <div class="goResetPass" @click="goResetPass">
                                忘记密码？
                            </div>
                        </div>
                        <div class="signBtn" @click="btn_login">
                            登录
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tipDialog" v-if="successDialog">
            <div class="fixContainer">
                <div class="successPng"></div>
                <div class="successText">{{successText+successTime+'s'}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SIdentify from "@/components/SIdentify";
    import {
        getHomeColumns,
        getHomeColumnsList
    } from '@/request/homepage';
    import {
        login,
        isLogin,
        loginOut
    } from "@/request/user";
    import {
        setCookie,
        getCookie,
        delCookie
    } from "@/utils/cookieManage";
    import {
        mapState,
        mapGetters,
        mapMutations
    } from 'vuex'
    export default {
        name: 'HeaderNav',
        props: {
            keepSearchShow: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                columns: [],
                showSearch: false,
                keyword: "",
                //登录
                showLoginDialog: false,
                userName: "", //账户
                password: "", //密码
                userVaildcode: "", //用户输入的验证码
                identifyCode: "", //验证码
                savePass: true, //记住密码
                //成功提示框
                successDialog: false,
                successText: "登录成功",
                successTime: 3,
                successTimer: undefined,
            };
        },
        created() {},
        mounted() {
            this.checkLoginStatus();
            this.getColumns();
            this.listenScroll();
            this.changeVaildCode();
            if (this.loginInfo) {
                this.userName = this.loginInfo.username;
                this.password = this.loginInfo.password;
            }
        },
        watch: {
            userInfo(val) {}
        },
        methods: {
            ...mapMutations(["store_login", "store_loginOut", "removeUserInfo"]),
            // 成功提示框
            setSuccessDialog(str) {
                this.successTime = 3;
                this.successText = str;
            },
            callSuccessDialog() {
                let that = this;
                this.successDialog = true;
                this.successTimer = setInterval(function () {
                    that.successTime = that.successTime - 1;
                    if (that.successTime <= 0) {
                        clearInterval(that.successTimer);
                        that.closeSuccessDialog();
                    }
                }, 1000)
            },
            closeSuccessDialog() {
                this.successDialog = false;
            },
            getColumns() {
                getHomeColumns({}).then(res => {
                    this.columns = res.data.filter(item => {
                        return item.name != "全部"
                    }).slice(0, 5);
                })
            },
            listenScroll() {
                let that = this
                window.addEventListener('scroll', function () {
                    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                    if (scrollTop >= 160) {
                        that.showSearch = true;
                    } else {
                        that.showSearch = false;
                    }
                })
            },
            /** 登录 */
            checkLoginStatus() {
                //通过后台拿登录状态，如果过期，清除本地用户信息
                isLogin({}).then(res => {
                    if (res.data === false) {
                        console.log("登录已过期，已清除用户信息")
                        this.removeUserInfo();
                    } else {

                    }
                })
                // 
                // if (this.$route.path == "/user") {
                //     this.$router.go("Index");
                // }
            },
            openLoginDialog() {
                this.showLoginDialog = true
            },
            closeLoginDialog() {
                this.showLoginDialog = false
            },
            //改变验证码
            changeVaildCode() {
                this.identifyCode = this.randomNum(1000, 9999).toString()
            },
            //生产随机验证码的事件
            randomNum(min, max) {
                return Math.floor(Math.random() * (max - min) + min)
            },
            btn_login() { //登录按钮
                let that = this;
                this.loginCheck(() => {
                    this.loginFun();
                })
            },
            loginCheck(fun) { //登录校验
                if (this.userName && this.password && this.userVaildcode) { //基本非空
                    //验证码
                    if (this.userVaildcode === this.identifyCode) {
                        fun();
                    } else {
                        this.userVaildcode = "";
                        this.$message.error("验证码错误")
                        this.changeVaildCode()
                    }
                } else {
                    this.$message.error("请检查登录信息")
                }
            },
            loginFun() { //登录及回调
                let that = this;
                this.store_login({
                    userName: this.userName,
                    password: this.password,
                    userVaildcode: this.userVaildcode,
                    keepNamePass: this.savePass, //是否保存登录名及密码-存于缓存
                    callbackFun: () => {
                        //清空本地表单
                        that.userName = "";
                        that.password = "";
                        that.userVaildcode = "";
                        that.setSuccessDialog("登录成功");
                        that.callSuccessDialog();
                        if ("/" !== this.$route.path) {
                            setTimeout(function () {
                                that.$router.go(0);
                            }, 2000);
                        }
                    }
                })
            },
            loginOut() { //退出登录
                let that = this;
                this.store_loginOut({
                    callbackFun: () => {
                        that.setSuccessDialog("退出成功");
                        that.callSuccessDialog();
                        if ("/" !== this.$route.path) {
                            setTimeout(function () {
                                that.$router.push({
                                    name: "Index"
                                })
                            }, 2000)
                        }
                    }
                })
            },
            /** 页面跳转 */
            goColumnSearch(column) { //跳转根据栏目查询的文章列表（不带搜索）
                let query = {};
                if (column.name != "全部") {
                    query.managementId = column.id;
                    query.managementName = column.name;
                    query.pageNum = 1
                }
                if ((this.$route.query.managementId && this.$route.query.managementId == column.id) && (this.$route
                        .query.pageNum && this.$route.query.pageNum == 1)) {
                    window.location.reload()
                    return
                }
                this.$router.push({
                    name: "ColumnSearch",
                    query: query
                })
            },
            goResetPass() { //重置密码
                this.$router.push({
                    name: "ResetPass"
                })
            },
            goSearch(column) { //跳转搜索页
                let query = {};
                if (column.name != "全部") {
                    query.managementId = column.id;
                    query.managementName = column.name;
                }
                this.$router.push({
                    name: "Search",
                    query: query
                })
            },
            goSearchKeyWord(keyword) { //跳转搜索页
                this.keyword = ""
                let random = 100000 * Math.random();
                let query = {
                    keyword: keyword,
                    random: random
                };
                if (this.$route.path == "/search") {
                    let thisHref = window.location.href.split("?")[0];
                    window.open(thisHref + `?keyword=${keyword}`);
                    return
                }
                this.$router.push({
                    name: "Search",
                    query: query
                })
            },
            goIndex() { //回首页
                this.$router.push({
                    name: "Index"
                })
            },
            goUser() { //个人信息
                this.$router.push({
                    name: "User"
                })
            },
            goSign() { //注册
                this.$router.push({
                    name: "Sign"
                })
            },
        },
        computed: {
            ...mapGetters(["getWebUserInfo", "getLoginInfo"]),
            userInfo() {
                return this.getWebUserInfo
            },
            loginInfo() {
                return this.getLoginInfo
            }
        },
        components: {
            SIdentify
        },
    };
</script>

<style scoped lang="scss">
    .container {
        z-index: 2;
        height: 80px;

        .fixBox {
            width: 100%;
            height: 80px;
            background: #fff;
            // background-image: url("../assets/img/headerBg.png");
            background-size: 100% 100%;
            position: fixed;
            top: 0;
            left: 0;

            .inner {
                width: 1200px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 auto;
                font-size: 16px;

                .searchBlock {
                    display: flex;
                    align-items: center;

                    .logo {
                        display: block;
                        width: 180px;
                        height: 54px;
                        background-image: url("../assets/img/PNG3.png");
                        background-size: cover;
                        margin-right: 39px;
                    }

                    .searchBox {
                        width: 250px;
                        height: 30px;
                        margin-right: 20px;
                        box-sizing: border-box;
                        padding: 0 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: 16px;
                        background-color: #2c71dd;
                        font-size: 14px;
                        background: #ffffff;
                        border: 1px solid #266ddc;
                        border-radius: 28px;

                        input {
                            width: 166px;
                            color: #266ddc;
                            background: transparent;
                        }

                        input::-webkit-input-placeholder {
                            color: #266ddc;
                        }

                        input::-moz-placeholder {
                            /* Mozilla Firefox 19+ */
                            color: #266ddc;
                        }

                        input:-moz-placeholder {
                            /* Mozilla Firefox 4 to 18 */
                            color: #266ddc;
                        }

                        input:-ms-input-placeholder {
                            /* Internet Explorer 10-11 */
                            color: #266ddc;
                        }

                        .searchIcon {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                .columnsBlock {
                    display: flex;
                    align-items: center;

                    .columns {
                        height: 34px;
                        margin-right: 30px;
                        line-height: 34px;
                        display: flex;
                        align-items: center;
                        border-right: 1px solid #266DDC;

                        span {
                            margin-right: 20px;
                            color: #266DDC;
                            cursor: pointer;
                        }

                        span:hover {
                            text-decoration: underline;
                        }
                    }

                    .unLogin {
                        width: 100px;
                        height: 28px;
                        border-radius: 14px;
                        color: white;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 28px;
                        display: flex;
                        justify-content: center;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        background: linear-gradient(to right, #4097EC, #3D81EA);

                        .login {
                            cursor: pointer;

                        }

                        .login:hover {
                            text-decoration: underline;
                        }

                        .sign {
                            cursor: pointer;
                        }

                        .sign:hover {
                            text-decoration: underline;
                        }
                    }

                    .hasLogin {
                        color: #266DDC;
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                        font-weight: bold;

                        .login {
                            max-width: 80px;
                            word-break: break-all;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                            overflow: hidden;
                            margin-right: 20px;
                            text-align: right;
                        }

                        .login:hover {
                            text-decoration: underline;
                        }

                        .loginOut {
                            cursor: pointer;
                        }

                        .loginOut:hover {
                            text-decoration: underline;
                        }
                    }
                }

            }
        }

        .loginDialog {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            z-index: 9999;
            position: fixed;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);

            .fixContainer {
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 100;

                .loginBox {
                    width: 900px;
                    height: 480px;
                    overflow: hidden;
                    position: absolute;
                    z-index: 100;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: #ffffff;
                    border-radius: 20px;
                    box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
                    display: flex;
                    align-items: center;

                    img {
                        width: 530px;
                        height: 480px;
                        margin-left: -2px;
                        display: block;
                    }

                    .loginBlock {
                        height: 480px;
                        position: relative;
                        z-index: 1;
                        width: 370px;
                        opacity: 0.9;
                        background: rgba(255, 255, 255, 0.9);
                        border-radius: 20px;

                        box-sizing: border-box;
                        padding-top: 70px;
                        padding-left: 50px;

                        .closeLogin {
                            width: 24px;
                            height: 24px;
                            position: absolute;
                            right: 16px;
                            top: 16px;

                            img {
                                width: 24px;
                                height: 24px;
                                cursor: pointer;
                            }
                        }

                        .tit {
                            font-size: 18px;
                            font-weight: 600;
                            text-align: left;
                            color: #266ddc;
                            line-height: 25px;
                            margin-bottom: 42px;
                        }

                        .row {
                            margin-bottom: 27px;

                            input {
                                height: 30px;
                                width: 270px;
                                font-size: 16px;
                                background: transparent;
                                border-bottom: 1px solid #266ddc;
                            }
                        }

                        .verfRow {
                            display: flex;
                            align-items: center;

                            input {
                                height: 30px;
                                width: 176px;
                                font-size: 16px;
                                border-bottom: 1px solid #266ddc;
                                margin-right: 15px;
                            }


                            .vaildBox {
                                width: 73px;
                                height: 32px;
                                cursor: pointer;
                            }
                        }

                        .btns {
                            display: flex;
                            align-items: center;



                            .savePass {


                                display: flex;
                                align-items: center;
                                color: #266DDC;
                                font-size: 16px;
                                margin-right: 63px;
                                cursor: pointer;

                                input {
                                    width: 16px;
                                    height: 16px;
                                    cursor: pointer;
                                    background: transparent;
                                }

                                span {
                                    margin-top: -2px;
                                }
                            }

                            .goRegister {
                                color: #266DDC;
                                font-size: 16px;
                                margin-right: 10px;
                                cursor: pointer;
                            }

                            .goRegister:hover {
                                text-decoration: underline;
                            }

                            .goResetPass {
                                color: #266DDC;
                                font-size: 16px;
                                cursor: pointer;
                            }

                            .goResetPass:hover {
                                text-decoration: underline;
                            }
                        }

                        .signBtn {
                            width: 270px;
                            height: 40px;
                            text-align: center;
                            line-height: 40px;
                            color: white;
                            background: linear-gradient(90deg, #4097ec, #3d81ea);
                            border-radius: 20px;
                            font-size: 18px;
                            margin-top: 20px;
                            cursor: pointer;
                        }

                    }
                }
            }

        }

        .tipDialog {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            z-index: 100;
            position: fixed;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0);

            .fixContainer {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 260px;
                height: 260px;
                box-sizing: border-box;
                opacity: 0.7;
                background: #100c3e;
                border-radius: 16px;
                padding-top: 77px;

                .successPng {
                    width: 78px;
                    height: 78px;
                    margin: 0 auto;
                    background: url("../assets/img/successImg.png");
                    background-size: 100% 100%;
                    margin-bottom: 30px;
                }

                .successText {
                    font-size: 20px;
                    font-weight: 500;
                    text-align: center;
                    color: #ffffff;
                    line-height: 28px;
                }
            }
        }
    }
</style>