<template>
    <div class='container'>
        <HeaderNav ref="headerNav" />
        <div class="banner">
            <!-- 顶部滚动文字轮播 -->
            <div class="textBanner">
                <div class="inner">
                    <div class="rollText">
                        <div class="showText" :style="'transform:translateX('+rollTextMl+'px)'">
                            {{rollText}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- 顶部搜索区域 -->
            <div class="face">
                <div class="inner">
                    <div class="centerBlock">
                    <div class="adwheelBlock" :style="{'height':topAdvHeight+'px '}" >
                            <!-- <div class="imgs"
                                :style="{'width':topAdvWidth+'px','transform':'translateX('+topAdvMl+'px)',}">
                                <img @click="openWeb(item.url)" :src="$RESOURCE_URL+item.picture" alt=""
                                    v-for="item in topAdvs"
                                    :style="{'width':topAdvWindowWidth+'px','height':topAdvHeight+'px'}" :key="item.id">
                                <img :style="{'width':topAdvWindowWidth+'px','height':topAdvHeight+'px'}" :src="$RESOURCE_URL+topAdvs[0]['picture']" alt="">
                            </div>
                            <div class="points">
                                <div @click.stop="touchPoints(i-1)" :class="{active:i-1==topAdvIndex}"
                                    v-for="i in topAdvs.length" :key="i">
                                </div>
                            </div> -->
                            <!-- <Swiper v-if="topAdvs.length" :options="swiperOption" ref="mySwiper"   >
                                <SwiperSlide v-for="(item) in topAdvs" :key="item.id+'mySwiper'" >
                                    <img :src="$RESOURCE_URL+item.picture" :style="{'height':topAdvHeight+'px'}" alt=""  @click="openWeb(item.url)"  />
                                
                                </SwiperSlide>
                              
                                <div class="swiper-pagination" slot="pagination"></div>
                                <div class="swiper-button-prev" slot="button-prev"></div>
                                <div class="swiper-button-next" slot="button-next"></div> 
                            </Swiper> -->
                            <!-- <el-carousel :style="{'width':'100%','height':topAdvHeight+'px'}" >
                            <el-carousel-item v-for="item in topAdvs" :key="item.id"  :style="{'width':'100%','height':topAdvHeight+'px'}">
                                <img  :style="{width: '100%','height':topAdvHeight+'px'}" :src="$RESOURCE_URL+item.picture"/>
                                </el-carousel-item>
                            </el-carousel> -->
                            <slider ref="slider" v-if="topAdvs.length" :options="options" :style="{'width':topAdvWindowWidth+'px','height':topAdvHeight+'px'}">
                                <!-- slideritem wrapped package with the components you need -->
                                <slideritem v-for="(item,index) in topAdvs" :key="item.id"  >
                                    <img :style="{'width':topAdvWindowWidth+'px','height':topAdvHeight+'px'}" :src="$RESOURCE_URL+item.picture"/></slideritem>
                                <!-- Customizable loading -->
                                <div slot="loading">loading...</div>
                            </slider>               
                        </div>
                        <div class="loginBox" v-if="!userInfo">
                            <div class="loginBlock" :style="{transform:'scale('+loginBoxScaleNum+')'}">
                                <div class="tit">登录/注册</div>
                                <div class="row accountRow">
                                    <input type="text" maxlength="20" v-model="userName" placeholder="请输入账户"
                                        @keyup.enter="btn_login">
                                </div>
                                <div class="row passRow">
                                    <input type="password" maxlength="20" v-model="password" placeholder="请输入密码"
                                        @keyup.enter="btn_login" />
                                </div>
                                <div class="row verfRow">
                                    <input type="text" maxlength="6" v-model="userVaildcode" placeholder="请输入验证码"
                                        @keyup.enter="btn_login" />
                                    <div class="vaildBox" @click="changeVaildCode">
                                        <SIdentify :contentWidth="120" :contentHeight="40"
                                            :identifyCode="identifyCode" />
                                    </div>
                                </div>
                                <div class="btns">
                                    <div class="savePass">
                                        <input type="checkbox" v-model="savePass" />
                                        <span>记住密码</span>
                                    </div>
                                    <div class="goRegister" @click="goSign">
                                        注册
                                    </div>
                                    <div class="goResetPass" @click="goResetPass">
                                        忘记密码？
                                    </div>
                                </div>
                                <div class="signBtn" @click="btn_login">
                                    登录
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="searchBlock">
                        <div class="searchBox">
                            <!-- input -->
                            <input type="text" placeholder="搜索名称 / 关键词" v-model="keyword"
                                @keyup.enter="goSearch({keyword:keyword})" maxlength="50" />
                            <!-- Icon -->
                            <img src="@/assets/img/searchIcon.png" alt="" class="searchIcon"
                                @click="goSearch({keyword:keyword})">
                        </div>
                        <div class="quickTip">
                            <span>热门搜索:</span>
                            <span class="hotKey" v-for="item in hotKeys" :key="item.id"
                                @click="setHotKey(item.name)">{{item.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="inner">
                <div class="projectInfo" v-for="i in 2" :key="i">
                    <div class="list-tit">{{columns[i-1].name}}</div>
                    <div class="list">
                        <div class="row" v-for="item in columnsTopics[i-1]" :key="item.id"
                            @click="goSearch({topic:item})">
                            <div class="tips">
                                <div class="tip areaTip">{{item.regionName.substring(0,3)}}</div>
                                <div class="tip columnTip">{{item.managementName}}</div>
                                <!-- <div class="tip industryTip">{{item.classificationName}}</div> -->
                            </div>
                            <div class="topicTit" :style="item.browsingType==1?'color:#678dc8':''">{{item.title}}</div>
                            <div class="topicTime">{{item.createDate.substring(0,10)}}</div>

                        </div>
                    </div>
                    <div class="list-more" @click="goSearch({column:columns[i-1]})">
                        <span>查看更多</span>
                    </div>
                </div>
                <div class="advImgs" >
                    <Swiper v-if="homepageData.middlePictureList" :options="swiperOption1" ref="mySwiper1"  >
                                <SwiperSlide v-for="(item) in homepageData.middlePictureList"  :key="item.id+'mySwiper1'" >
                                    <img :src="$RESOURCE_URL+item.picture" alt=""  @click="openWeb(item.url)"  />
                                
                                </SwiperSlide>
                              
                                <!-- <div class="swiper-pagination" slot="pagination"></div>
                                <div class="swiper-button-prev" slot="button-prev"></div>
                                <div class="swiper-button-next" slot="button-next"></div> -->
                            </Swiper>
                    <!-- <div class="advImg" :style="{'margin-top':middleAdvMl+'px','width':middleAdvWidth+'px','transition':`all ${ition}ms`}">
                        <img :src="$RESOURCE_URL+item.picture" alt="" v-for="item in homepageData.middlePictureList"
                            :key="item.id" >
                         
                    </div> -->
                </div>
                <div class="projectInfoBox">
                    <div class="rectangle" v-for="i in 3" :key="i">
                        <div class="list-tit">{{columns[i+1].name}}</div>
                        <div class="row" v-for="item in columnsTopics[i+1]" :key="item.id"
                            @click="goSearch({topic:item})">
                            <div class="topicTit" :style="item.browsingType==1?'color:#678dc8':''" :title="item.title">
                                {{item.title}}</div>
                            <div class="topicInfo">
                                <div class="tips">
                                    <div class="tip areaTip">{{item.regionName.substring(0,3)}}</div>
                                    <div class="tip industryTip">{{item.classificationName}}</div>
                                </div>
                                <div class="topicTime">{{item.createDate.substring(0,10)}}</div>
                            </div>
                        </div>
                        <div class="list-more" @click="goSearch({column:columns[i+1]})">
                            <span>查看更多</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="industries">
                <div class="tit">覆盖行业</div>
                <div class="contentBox">
                    <div class="btn_left" v-if="canMove" @click="changeIndustriesML(0)"
                        v-show="industriesML>industriesMinML+ (8 * 112)"></div>
                    <div class="btn_right" v-if="canMove" @click="changeIndustriesML(1)" v-show="industriesML!=0"></div>
                    <div class="overflowBox">
                        <div class="infoBox" :style="'margin-left:'+industriesML+'px'">
                            <!-- 每列宽度92+20，展示8列 -->
                            <div class="infoCol" v-for="(col,colIndex) in industries" :key="colIndex">
                                <div class="infoItem" v-for="item in col" :key="item.id"
                                    @click="goSearch({industry:item})">
                                    <img :src="$RESOURCE_URL+item.picture" alt="">
                                    <div>{{item.name}}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="friend" @mouseenter="pauseRollFriend">
                <div class="module-tit">合作伙伴</div>
                <div class="imgBanner">
                    <div class="imgShowBox" :style="{width:friendsWidth+'px','margin-left':-showFriendIndex*244+'px'}">
                        <div class="imgArr" v-for="(imgArr,imgArrIndex) in friends" :key="'imgArr'+imgArrIndex">
                            <img :src="$RESOURCE_URL+item.picture" alt="" v-for="(item,index) in imgArr"
                                :key="'imgArr'+imgArrIndex+item.url+index" @click="openWeb(item.url)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="friendLink">
                <div class="module-tit">友情链接</div>
                <div class="links">
                    <a v-for="item in homepageData.linksList.slice(0,7)" :key="item.id"
                        :href="item.url">{{item.name}}</a>
                </div>
                <div class="links" v-if="homepageData.linksList.length>7">
                    <a v-for="item in homepageData.linksList.slice(7,14)" :key="item.id"
                        :href="item.url">{{item.name}}</a>
                </div>
                <div class="links" v-if="homepageData.linksList.length>14">
                    <a v-for="item in homepageData.linksList.slice(14,21)" :key="item.id"
                        :href="item.url">{{item.name}}</a>
                </div>
            </div>
            <div class="vipService">
                <div class="module-tit">会员服务</div>
                <!-- <div class="introduce">
                    <div>VIP特色介绍特色介绍特色介绍特色介绍特色介绍</div>
                </div> -->
                <img :src="$RESOURCE_URL+homepageData.basisSetting.homePagePic" alt="" class="introduceImg">
            </div>

        </div>
        <FooterNav />
        <SlideFixNav />
    </div>
</template>

<script>
    import HeaderNav from "@/components/HeaderNav"
    import FooterNav from "@/components/FooterNav"
    import SlideFixNav from "@/components/SlideFixNav"
    import SIdentify from "@/components/SIdentify"
    import { slider, slideritem } from 'vue-concise-slider'
    //apis
    import {
        getRollText,
        getHomepage,
        getHomeAdv,
        getHomeColumns,
        getHomeColumnsList

    } from '@/request/homepage';
    import {
        getTopicsByColumn

    } from '@/request/column';
    import {
        getIndustry
    } from "@/request/industry";
    import {
        getHotKey
    } from "@/request/search";
    import {
        login
    } from "@/request/user";
    import {
        viewCount
    } from "@/request/viewCount";
    import {
        setCookie,
        getCookie,
        delCookie
    } from "@/utils/cookieManage";
    import {
        mapState,
        mapGetters,
        mapMutations
    } from 'vuex'
    // 引入swiper插件
    import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

    export default {
        name: 'Index',
        props: {

        },
        data() {
            return {
                options: {
          currentPage: 0,
          loop: true,
          autoplay:'5000',
        //   thresholdDistance: 1000,
  

        },
                imgList:[],
                leftVal:0,  // 轮播图盒子的偏移值
        flag:true, // 用来节流防止重复点击
        start:null, // 自动执行下一张定的时器
        imgWidth:500,  // 在这里填写你需要的图片宽度
        ition:0.8, // 设置轮播图过度时间
        imgShow:0, //
                swiperOption1:{
                    loop: true,
                    direction: "vertical",

                    autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                    },
                    // 显示分页
                    // pagination: {
                    // el: ".swiper-pagination",
                    // clickable: true, //允许分页点击跳转
                 
                    // },
             
                    // 设置点击箭头
                    // navigation: {
                    // nextEl: ".swiper-button-next",
                    // prevEl: ".swiper-button-prev"
                    // }
                },
                swiperOption: {
                    loop: true,
                    // direction: "vertical",
                    grabCursor: false, 
                    direction: 'horizontal',
                    initialSlide: 0,
                    //自动播放
                    autoplay: true,
                  
                    autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                    },
                    // 显示分页
                    pagination: {
                    el: ".swiper-pagination",
                     //允许分页点击跳转
                  
                 
                    },
                 on: {
                    slideChange () {
                        // console.log('onSlideChangeEnd', this)
                    },
                    tap () {
                        console.log('onTap', this)
                      
                    }
                  },
                    // 设置点击箭头
                    navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                    },
                   
                  },

                //数据
                homepageData: {
                    homePagePictureList: [],
                    linksList: [],
                    basisSetting: {}
                },
                industries: [], //行业列表
                canMove: false, //行业列表左右按钮
                columns: [{}, {}, {}, {}, {}], // 栏目列表
                //滚动文字
                rollText: "", //滚动文字
                rollTextMin: 0, //可滚动距离，超出归0
                rollTextMl: 1200, //当前滚动距离，默认1200，起始右边到左边滚动
                rollTextInt: undefined, //滚动定时器
                //搜索
                hotKeys: [{}, {}, {}, {}, {}], //热搜关键字
                keyword: "", //搜索关键字
                //登录
                loginBoxScaleNum: 1, //登录框比例
                userName: "", //账户
                password: "", //密码
                userVaildcode: "", //用户输入的验证码
                identifyCode: "", //验证码
                savePass: true, //记住密码

                /** 轮播 */
                //顶部轮播
                topAdvWindowWidth: 1920,
                topAdvs: [],
                topAdvWidth: 10000,
                topAdvMl: 0,
                topAdvIndex: 0,
                topAdvRollSeconds: 3000,
                topAdvRollInt: undefined,
                topAdvRollPause: undefined,
                //中部轮播
                middleAdvs: [], //轮播数据
                middleAdvWidth: 10000,
                middleAdvMl: 0,
                middleAdvIndex: 0,
                middleAdvRollSeconds: 3000,
                middleAdvRollInt: undefined,
                middleAdvRollPause: undefined,
                //行业轮播
                industriesMinML: 0,
                industriesML: 0, //行业列表margin-left值
                //合作伙伴（底部轮播）
                friends: [],
                friendsWidth: 10000,
                showFriendIndex: 0,
                friendsRollSeconds: 3000, //轮播间隔
                friendsRollInt: undefined, //轮播定时器
                friendRollPause: undefined,
                //栏目文章列表
                columnsTopics: [
                    [],
                    [],
                    [],
                    [],
                    []
                ], //对应5个栏目文章列表
                ition:0
            };
        },
        computed: {
            ...mapGetters(["getWebUserInfo", "getLoginInfo"]),
            userInfo() {
                return this.getWebUserInfo
            },
            loginInfo() {
                return this.getLoginInfo
            },
            swiper() {
                return this.$refs.mySwiper.swiper;
                },
             },
        created() {
            this.afterRiseWindow();
            window.onresize = () => {
                // that.topAdvWindowWidth = window.innerWidth > 1300 ? window.innerWidth : 1300;
                // that.topAdvHeight = that.topAdvWindowWidth * bili; //顶部轮播等比宽
                this.setTopWidth(this.topAdvs);
                this.afterRiseWindow();
                this.topAdvMl = -this.topAdvWindowWidth * this.topAdvIndex;
                // console.log(this.topAdvMl);
            }
            viewCount(); //访问计数
            this.changeVaildCode()
        },
        mounted() {
          
            //滚动文字
            getRollText({}).then(res => {
                this.rollText = res.data.content;
                this.startRollText(this.rollText)
            })
            //首页数据
            getHomepage({}).then(res => {
                this.homepageData = res.data;
                this.friends = this.fixData_friend(this.homepageData.bottomPictureList);
                this.friendsWidth = this.setFriendBannerWidth(this.friends)
               
                this.topAdvs = this.homepageData.homePagePictureList;
              this.imgList = this.homepageData.homePagePictureList.map(item=>item.picture);
                this.startRollTop()
                this.setTopWidth(this.topAdvs);
                this.middleAdvs = this.homepageData.middlePictureList;
                this.startRollMiddle()
                this.setMiddleWidth(this.middleAdvs);
                this.startRollFriends()
            })
            //行业列表
            getIndustry({}).then(res => {
                if (res.data) {
                    //需要轮播，每列展示2个，所以进行数据处理
                    //筛除全国、全部
                    let arr = res.data.filter(item => {
                        return item.name != "其他" && item.name != "全国" && item.name != "全部"
                    })
                    let industries = [];
                    let container = [];
                    arr.forEach((item, index) => {
                        if (container.length < 2) {
                            container.push(item);
                        } else {
                            industries.push(container);
                            container = [];
                            container.push(item);
                        }
                        if (index == arr.length - 1) {
                            industries.push(container);
                        }
                    })
                    this.industries = industries;
                    this.canMove = industries.length > 8;
                    this.industriesMinML = -(industries.length * 112);
                }

            })
            //热搜关键字
            getHotKey({}).then(res => {
                this.hotKeys = res.data;
            })
            //首页栏目
            getHomeColumnsList({}).then(res => {
                this.columns = res.data;
                this.getColumnsData(this.columns)
            })
            //检测登录，未登录是否保存了账号信息
            // if (!this.$store.state.userInfo) {
            //     if (getCookie('account') != "null" && getCookie('pwd') != "null") {
            //         this.userName = getCookie('account');
            //         this.password = getCookie('pwd');
            //         this.savePass = true;
            //     }
            // } else {
            //     delCookie('account');
            //     delCookie('pwd');
            // }
            if (this.loginInfo) {
                this.userName = this.loginInfo.username;
                this.password = this.loginInfo.password;
            }
//   console.log('this is current swiper instance object', this.swiper())
        },
        beforeDestroy() {
            this.closeRollText()
            this.closeRollFriend()
            this.closeRollMiddle()
            this.closeRolltop()
        },
        watch: {
            keyword(val) {
                this.$refs.headerNav.keyword = val;
            }
        },
        methods: {
              // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
    
            ...mapMutations(["store_login", "store_loginOut", "removeUserInfo"]),
            //浏览器可视区域宽高变化后
            afterRiseWindow() {
                let that = this;
                let bili = 570 / 1920;
                this.topAdvWindowWidth = window.innerWidth > 1300 ? window.innerWidth : 1300;
                this.topAdvHeight = this.topAdvWindowWidth * bili;
                let scaleNum = window.innerWidth / 1920;
                scaleNum = scaleNum > 1 ? 1 : scaleNum;
                scaleNum = scaleNum < 0.7 ? 0.7 : scaleNum;
                that.loginBoxScaleNum = scaleNum;
            },
            /** 滚动文字 */
            //开启滚动
            startRollText(str) {
                let that = this;
                this.rollTextMin = -(str.length * 12);
                this.rollTextInt = setInterval(function () {
                    if (that.rollTextMin < that.rollTextMl) {
                        that.rollTextMl = that.rollTextMl - 1;
                    } else {
                        that.rollTextMl = 1200;
                    }
                }, 20)
            },
            //关闭滚动
            closeRollText() {
                let that = this;
                clearInterval(that.rollTextInt);
                that.rollTextInt = undefined;
            },
            /** 搜索框 */
            //选中热搜关键词
            setHotKey(key) {
                this.keyword = key;
                this.goSearch({
                    keyword: key
                })
            },
            //搜索跳转
            goSearch(opt) {
                let query = {};
                let urlName = "";
                // 文章详情
                if (opt.topic) {
                    urlName = "Topic"
                    query = {
                        id: opt.topic.uuid
                    }
                    let routeUrl = this.$router.resolve({
                        name: urlName,
                        query: query
                    });
                    window.open(routeUrl.href, '_blank');
                } else {
                    urlName = "Search"
                    // 搜索页面
                    if (opt.keyword) { //关键字
                        query = {
                            keyword: opt.keyword
                        }
                    } else if (opt.industry) { //行业
                        query = {
                            classificationId: opt.industry.id,
                            classificationName: opt.industry.name
                        }
                    } else if (opt.column) { //栏目
                        urlName = "ColumnSearch";
                        query = {
                            managementId: opt.column.id,
                            managementName: opt.column.name
                        }
                    }
                    this.$router.push({
                        name: urlName,
                        query: query
                    });
                }


            },
            /** 登录 */
            //改变验证码
            changeVaildCode() {
                this.identifyCode = this.randomNum(1000, 9999).toString()
            },
            //生产随机验证码的事件
            randomNum(min, max) {
                return Math.floor(Math.random() * (max - min) + min)
            },
            //登录
            btn_login() {
                this.loginCheck(() => {
                    this.loginFun();
                })
            },
            loginCheck(fun) { //登录校验
                if (this.userName && this.password && this.userVaildcode) { //基本非空
                    //验证码
                    if (this.userVaildcode === this.identifyCode) {
                        fun();
                    } else {
                        this.userVaildcode = "";
                        this.$message.error("验证码错误")
                        this.changeVaildCode()
                    }
                } else {
                    this.$message.error("请检查登录信息")
                }
            },
            loginFun() { //登录及回调
                let that = this;
                this.store_login({
                    userName: this.userName,
                    password: this.password,
                    userVaildcode: this.userVaildcode,
                    keepNamePass: this.savePass, //是否保存登录名及密码-存于缓存
                    callbackFun: () => {
                        //清空本地表单
                        that.userName = "";
                        that.password = "";
                        that.userVaildcode = "";
                        that.$refs.headerNav.setSuccessDialog("登录成功");
                        that.$refs.headerNav.callSuccessDialog();
                        if ("/" !== this.$route.path) {
                            setTimeout(function () {
                                that.$router.go(0);
                            }, 2000);
                        }
                    }
                })
            },
            /** 页面跳转 */
            //注册
            goSign() {
                this.$router.push({
                    name: "Sign"
                })
            },
            //重置密码
            goResetPass() {
                this.$router.push({
                    name: "ResetPass"
                })
            },
            /** 轮播图 */
            //顶部轮播
            setTopWidth(arr) {
                this.topAdvWidth = arr.length * this.topAdvWindowWidth;
                // this.imgList = arr.length * this.topAdvWindowWidth;
            },
            //点击points改变index
            touchPoints(i) {
                this.topAdvIndex = i;
                this.topAdvMl = -this.topAdvWindowWidth * this.topAdvIndex;
                this.pauseRollTop();
            },
            //暂停轮播
            pauseRollTop() {
                let that = this;
                this.closeRolltop();
                if (this.topAdvRollPause) {
                    clearTimeout(that.topAdvRollPause)
                }
                this.topAdvRollPause = setTimeout(function () {
                    that.startRollTop();
                    that.topAdvRollPause = undefined;
                }, that.topAdvRollSeconds)
            },
            //轮播
            startRollTop() {
                let that = this;
                if (that.topAdvs.length > 1) {
                    clearInterval(this.topAdvRollInt)
                    this.topAdvRollInt = setInterval(function () {
                        if (that.topAdvIndex < that.topAdvs.length - 1) {
                            that.topAdvIndex = that.topAdvIndex + 1;
                        } else {
                            that.topAdvIndex = 0
                        }
                        that.topAdvMl = -that.topAdvWindowWidth * that.topAdvIndex;
                    }, this.topAdvRollSeconds)
                }
            },
            closeRolltop() {
                let that = this;
                clearInterval(that.topAdvRollInt);
                this.topAdvRollInt = undefined;
            },
            //中部轮播
            //获取轮播宽度
            setMiddleWidth(arr) {
                this.middleAdvWidth = arr.length * 1200;
            },
            //暂停轮播
            pauseRollMiddle() {
                let that = this;
                this.closeRollMiddle();
                if (this.middleAdvRollPause) {
                    clearTimeout(that.middleAdvRollPause)
                }
                this.middleAdvRollPause = setTimeout(function () {
                    that.startRollMiddle();
                    that.middleAdvRollPause = undefined;
                }, that.middleAdvRollSeconds)
            },
            //轮播
            startRollMiddle() {
                let that = this;
                if (that.middleAdvs.length > 1) {
                    this.middleAdvRollInt = setInterval(function () {
                        if (that.middleAdvIndex < that.middleAdvs.length - 1) {
                            that.ition=500
                            that.middleAdvIndex = that.middleAdvIndex + 1;
                          
                        
                                        
                        } else {
                            that.ition = 0
                            that.middleAdvIndex = 0;
                        }
                        that.middleAdvMl = -252 * that.middleAdvIndex;
                        // console.log(that.middleAdvMl);//这里代表 升上去的高度
                    }, this.middleAdvRollSeconds)
                }
            },
            closeRollMiddle() {
                clearInterval(this.middleAdvRollInt);
                this.middleAdvRollInt = undefined;
            },
            //行业轮播
            changeIndustriesML(type) {
                if (this.canMove) {
                    let minML = this.industriesMinML;
                    switch (type) {

                        case 0: //向左滚
                            this.industriesML = this.industriesML > minML + (8 * 112) ? this.industriesML - 112 : this
                                .industriesML;
                            break;

                        case 1: //向右滚
                            this.industriesML = this.industriesML == 0 ? 0 : this.industriesML + 112;

                            break;
                    }
                }

            },
            //伙伴轮播（底部轮播）数据处理（1维->2维）
            fixData_friend(arr) {
                //每10个一组
                let friends = [];
                if (arr.length) {
                    for (let i = 0; i < Math.ceil(arr.length / 2); i++) {
                        let subArr = arr.slice(i * 2, i * 2 + 2);
                        if (subArr.length > 0) {
                            friends.push(subArr)
                        }
                    }
                }
                return friends;
            },
            setFriendBannerWidth(arr) {
                return arr.length * 224
            },
            //暂停轮播
            pauseRollFriend() {
                let that = this;
                this.closeRollFriend();
                if (this.friendRollPause) {
                    clearTimeout(that.friendRollPause)
                }
                this.friendRollPause = setTimeout(function () {

                    that.startRollFriends();
                    that.friendRollPause = undefined;
                }, that.friendsRollSeconds)
            },
            //伙伴轮播-开启轮播
            startRollFriends() {
                let that = this;
                if (this.friends.length > 5) {
                    this.friendsRollInt = setInterval(function () {
                        that.showFriendIndex = that.showFriendIndex + 1;
                        that.friends.push(that.friends[that.showFriendIndex - 1])
                    }, this.friendsRollSeconds)
                }
            },
            //伙伴轮播-关闭轮播
            closeRollFriend() {
                clearInterval(this.friendsRollInt);
                this.friendsRollInt = undefined;
            },
            //链接跳转
            openUrl(event){
                console.log(event);
                if(event.target.namespaceURI){
                    window.open(event.target.namespaceURI, "_blank");
                }
            },
            openWeb(url) {
                console.log(url);
                if (url) {
                    window.open(url, "_blank");

                }
            },
            //栏目数据
            getColumnsData(arr) {
                if (arr.length) {
                    arr.forEach((item, index) => {
                        let pageSize = index >= 2 ? 5 : 10;
                        getTopicsByColumn(null, {
                            currentPage: 1,
                            id: item.id,
                            pageSize: pageSize
                        }).then(res => {
                            this.$set(this.columnsTopics, index, res.data.slice(0, pageSize));
                        })
                    })
                }
            }
        },
        components: {
            HeaderNav,
            FooterNav,
            SIdentify,
            SlideFixNav,
            Swiper,
            SwiperSlide,
            slider,
      slideritem

        },
    };
</script>
<style scoped lang="scss">
::v-deep .swiper-container-horizontal > * > .slider-pagination-bullet {
    opacity: 0.7 ;
}
::v-deep .slider-pagination-bullet-active{
    width: 30px !important;
    height: 10px !important;
    opacity: 1 !important;
    background: #ffffff !important;
    border-radius: 6px !important;
}
::v-deep .el-carousel__button{
    width: 10px;
    height: 10px;
    opacity: 0.6;
    background: #ffffff;
    border-radius: 6px;
}


 
 
  .el-carousel__item:nth-child(2n+1) {
    background-color: #ffff;
  }
.SwiperBox {
    position: relative;
    width: 500px;
    height: 300px;
    box-sizing: border-box;
    // cursor: pointer;
    overflow: hidden;
  }
  .imgBox{
    position: absolute;
    top: 0px;
    left: 0px;
    min-width: 500px;
    height: 300px;
    display: flex;
    justify-content: flex-start;
  }
  
.swiper-main {
  width: 100%;
  height: 100%;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-container img {
  width: 100%;
  height: 100%;
}
::v-deep .swiper-pagination-bullet{
    opacity: 0.6;
}
::v-deep .swiper-pagination-bullet-active {

    width: 30px;
    height: 10px;
    opacity: 1;
    background: #ffffff;
    border-radius: 6px;
}

    .inner {
        width: 1200px;
        margin: 0 auto;
    }

    .banner {
        width: 100%;
        position: relative;
        // background-image: url("../assets/img/indexBanner.png");
        // background-repeat: no-repeat;
        // background-size: 100% 100%;

        .textBanner {
            width: 100%;
            height: 36px;
            top: 0;
            left: 0;
            z-index: 1;
            position: absolute;
            background: rgba(255, 255, 255, 0.21);

            .rollText {
                width: 1200px;
                overflow: hidden;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                color: #ffffff;
                line-height: 36px;

                .showText {
                    width: 10000px;
                    transform: translateX(0);
                    white-space: nowrap;
                }
            }
        }

        .face {
            width: 100%;
            position: relative;
            z-index: 0;

            .inner {
                width: 100%;
            }

            .searchBlock {
                width: 700px;
                margin: 0 auto;
                margin-top: 34px;


                .searchBox {
                    height: 55px;
                    padding: 0 35px;
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 28px;
                    background: rgba(255, 255, 255, 0.8);
                    font-size: 14px;
                    margin-bottom: 10px;
                    background: #ffffff;
                    border: 1px solid #266ddc;
                    border-radius: 28px;


                    input {
                        background: transparent;
                        color: #333333;
                        width: 520px;
                        height: 55px;
                        line-height: 55px;
                        font-size: 16px;
                    }

                    .searchIcon {
                        width: 23px;
                        height: 23px;
                    }
                }

                .quickTip {
                    padding-left: 25px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #266ddc;
                    line-height: 20px;

                    span {
                        margin-right: 12px;
                    }

                    .hotKey {
                        cursor: pointer;
                    }

                    .hotKey:hover {
                        text-decoration: underline;
                    }
                }
            }

            .centerBlock {
                width: 100%;
                position: relative;

                .adwheelBlock {
                    // cursor: pointer;
                    width: 100%;
                    max-height: 570px;
                    overflow: hidden;
                    position: relative;
                    z-index: 1;
                    box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
                    background-color: #222832;

                    .imgs {
                        width: 100%;
                        transform: translateX(0);
                        transition: all 1000ms;
                        max-height: 570px;
                        position: relative;
                        overflow: hidden;
                        z-index: 0;

                        img {
                            display: block;
                            // width: 100%;
                            max-height: 570px;
                            float: left;
                        }
                    }

                    .points {
                        position: absolute;
                        bottom: 16px;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 16px;
                        padding: 0 5px;
                        z-index: 1;
                        background: rgba(0, 0, 0, 0.3);
                        border-radius: 8px;
                        display: flex;
                        align-items: center;

                        div {
                            width: 10px;
                            height: 10px;
                            margin-right: 10px;
                            opacity: 0.7;
                            background: #ffffff;
                            border-radius: 6px;
                            cursor: pointer;
                            transition: all 500ms;
                        }

                        div:last-child {
                            margin-right: 0;
                        }

                        div:hover {
                            opacity: 1;
                        }

                        .active {
                            width: 30px;
                            height: 10px;
                            opacity: 1;
                            background: #ffffff;
                            border-radius: 6px;
                        }
                    }
                }

                .loginBox {
                    width: 1200px;
                    z-index: 10;
                    position: absolute;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);

                    .loginBlock {
                        position: absolute;
                        top: 4vw;
                        right: 0;
                        z-index: 1;
                        width: 309px;
                        opacity: 0.9;
                        background: rgba(255, 255, 255, 0.9);
                        border-radius: 20px;
                        box-sizing: border-box;
                        padding-top: 30px;
                        padding-left: 21px;
                        padding-bottom: 30px;
                        transform-origin: top;

                        .tit {
                            font-size: 18px;
                            font-weight: 600;
                            text-align: left;
                            color: #266ddc;
                            line-height: 25px;
                            margin-bottom: 32px;
                        }

                        .row {
                            margin-bottom: 27px;

                            input {
                                height: 30px;
                                width: 270px;
                                font-size: 16px;
                                background: transparent;
                                border-bottom: 1px solid #266ddc;
                            }
                        }

                        .verfRow {
                            display: flex;
                            align-items: flex-start;

                            input {
                                height: 30px;
                                width: 126px;
                                font-size: 16px;
                                border-bottom: 1px solid #266ddc;
                                margin-right: 15px;
                            }


                            .vaildBox {
                                width: 73px;
                                height: 32px;
                                cursor: pointer;
                            }
                        }

                        .btns {
                            display: flex;
                            align-items: center;



                            .savePass {


                                display: flex;
                                align-items: center;
                                color: #266DDC;
                                font-size: 16px;
                                margin-right: 63px;
                                cursor: pointer;

                                input {
                                    width: 16px;
                                    height: 16px;
                                    cursor: pointer;
                                    background: transparent;
                                }

                                span {
                                    margin-top: -2px;
                                }
                            }

                            .goRegister {
                                color: #266DDC;
                                font-size: 16px;
                                margin-right: 10px;
                                cursor: pointer;
                            }

                            .goRegister:hover {
                                text-decoration: underline;
                            }

                            .goResetPass {
                                color: #266DDC;
                                font-size: 16px;
                                cursor: pointer;
                            }

                            .goResetPass:hover {
                                text-decoration: underline;
                            }
                        }

                        .signBtn {
                            width: 270px;
                            height: 40px;
                            text-align: center;
                            line-height: 40px;
                            color: white;
                            background: linear-gradient(90deg, #4097ec, #3d81ea);
                            border-radius: 20px;
                            font-size: 18px;
                            margin-top: 20px;
                            cursor: pointer;
                        }

                    }
                }

            }
        }
    }

    .content {
        background: #f3f9ff;

        .inner {
            padding: 38px 0 38px 0;

            .projectInfo {
                position: relative;
                width: 1200px;
                height: 560px;
                margin-bottom: 30px;
                background: #ffffff;
                border-radius: 16px;
                box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);

                .list-tit {
                    width: 178px;
                    padding: 20px 0;
                    font-size: 20px;
                    font-weight: bold;
                    color: #266ddc;
                    text-align: center;
                    margin: 0 auto;
                    background-image: url("../assets/img/list_tit_left.png"), url("../assets/img/list_tit_right.png");
                    background-repeat: no-repeat;
                    background-position: left center, right center;
                    background-size: 14px 14px;
                }

                .list-more {
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 86px;
                    font-size: 16px;
                    color: #266ddc;
                    background-image: url("../assets/img/arrow-circle_right.png");
                    background-size: 16px 16px;
                    background-repeat: no-repeat;
                    background-position: right center;
                    cursor: pointer;
                }

                .list-more:hover {
                    text-decoration: underline;
                }

                .list {
                    padding: 0 30px;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    .row {
                        cursor: pointer;
                        margin-bottom: 16px;
                        display: flex;
                        align-items: center;

                        .tips {
                            display: flex;
                            align-items: center;
                            margin-right: 19px;

                            .tip {
                                width: 80px;
                                height: 28px;
                                box-sizing: border-box;
                                padding: 0 12px;
                                border-radius: 14px;
                                font-weight: 400;
                                text-align: center;
                                color: #ffffff;
                                line-height: 28px;
                                margin-right: 9px;
                                overflow: hidden;
                            }

                            .tip:last-child {
                                margin-right: 0;
                            }

                            .areaTip {
                                background: #2984ff;
                            }

                            .columnTip {
                                background: #00b9d4;
                            }

                            .industryTip {
                                background: #ff8b00;
                            }
                        }

                        .topicTit {
                            width: 740px;
                            height: 28px;
                            font-size: 16px;
                            font-weight: 400;
                            text-align: left;
                            color: #333333;
                            line-height: 28px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            margin-right: 17px;
                        }

                        .topicTime {
                            font-size: 16px;
                            font-weight: 400;
                            text-align: left;
                            color: #999999;
                            line-height: 22px;
                            margin-right: 16px;
                        }


                    }

                    .row:hover {
                        .topicTit {
                            color: #266ddc;
                        }

                        .topicTime {
                            color: #266ddc;
                        }
                    }

                }
            }

            .advImgs {
                width: 1200px;
                height: 252px;
                overflow: hidden;
                margin-bottom: 40px;
                cursor: pointer;

                .advImg {
                    width: 1200px;
                    height: 200000px;
                    margin-top: 0;
                    // transition: margin 500ms;

                    img {
                        width: 1200px;
                        height: 252px;
                        display: block;
                    }
                }
            }

            .projectInfoBox {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .rectangle {
                    position: relative;
                    width: 395px;
                    height: 540px;
                    overflow: hidden;
                    background: #ffffff;
                    border-radius: 16px;
                    box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);

                    .list-tit {
                        width: 178px;
                        padding: 20px 0;
                        font-size: 20px;
                        font-weight: bold;
                        color: #266ddc;
                        text-align: center;
                        margin: 0 auto;
                        background-image: url("../assets/img/list_tit_left.png"), url("../assets/img/list_tit_right.png");
                        background-repeat: no-repeat;
                        background-position: left center, right center;
                        background-size: 14px 14px;
                        margin-bottom: 2px;
                    }

                    .row {
                        width: 360px;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        cursor: pointer;

                        .topicTit {
                            font-size: 16px;
                            height: 28px;
                            font-weight: 400;
                            text-align: left;
                            color: #333333;
                            line-height: 28px;
                            margin-bottom: 8px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            overflow: hidden;
                        }

                        .topicInfo {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .tips {
                                display: flex;
                                align-items: center;
                                margin-right: 19px;

                                .tip {
                                    width: 80px;
                                    height: 28px;
                                    box-sizing: border-box;
                                    padding: 0 12px;
                                    border-radius: 14px;
                                    font-weight: 400;
                                    text-align: center;
                                    color: #ffffff;
                                    line-height: 28px;
                                    margin-right: 9px;
                                    overflow: hidden;
                                }

                                .tip:last-child {
                                    margin-right: 0;
                                }

                                .areaTip {
                                    background: #2984ff;
                                }

                                .columnTip {
                                    background: #00b9d4;
                                }

                                .industryTip {
                                    background: #ff8b00;
                                    display: none;
                                }
                            }

                            .topicTime {
                                font-size: 16px;
                                font-weight: 400;
                                text-align: left;
                                color: #999999;
                                line-height: 22px;
                            }
                        }
                    }

                    .row:hover {
                        .topicTit {
                            color: #266ddc;
                        }

                        .topicTime {
                            color: #266ddc;
                        }
                    }

                    .list-more {
                        position: absolute;
                        bottom: 19px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 86px;
                        font-size: 16px;
                        color: #266ddc;
                        background-image: url("../assets/img/arrow-circle_right.png");
                        background-size: 16px 16px;
                        background-repeat: no-repeat;
                        background-position: right center;
                        cursor: pointer;
                    }

                    .list-more:hover {
                        text-decoration: underline;
                    }
                }

            }
        }

        .industries {
            width: 100%;
            height: 350px;
            background-image: url("../assets/img/industryBg.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-bottom: 50px;

            .tit {
                text-align: center;
                font-weight: 900;
                font-size: 20px;
                color: #ffffff;
                padding: 30px 0 26px 0;
            }

            .contentBox {
                position: relative;
                width: 896px;
                padding: 0 54px;
                margin: 0 auto;

                .btn_left {
                    position: absolute;
                    width: 32px;
                    height: 32px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-image: url("../assets/img/right_arrow.png");
                    background-size: 32px 32px;
                    cursor: pointer;
                }

                .btn_right {
                    position: absolute;
                    width: 32px;
                    height: 32px;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-image: url("../assets/img/left_arrow.png");
                    background-size: 32px 32px;
                    cursor: pointer;
                }

                .overflowBox {
                    width: 896px;
                    overflow: hidden;

                    .infoBox {
                        width: 3000px;
                        transition: all 500ms;

                        .infoCol {
                            float: left;
                            margin-right: 20px;

                            .infoItem {
                                width: 92px;
                                height: 92px;
                                box-sizing: border-box;
                                background: rgba(209, 240, 255, 0.1);
                                border: 2px solid rgba(255, 255, 255, 0.5);
                                border-radius: 10px;
                                // box-shadow: 0px 0px 5px 0px #d1f0ff;
                                text-align: center;
                                color: #ffffff;
                                line-height: 22px;
                                padding-top: 21px;
                                font-size: 14px;
                                margin-bottom: 18px;
                                cursor: pointer;

                                div {
                                    width: 88px;
                                    box-sizing: border-box;
                                    padding: 0 2px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                img {
                                    width: 30px;
                                    height: 30px;
                                }

                                transition: all 500ms;

                            }

                            .infoItem:hover {
                                background: rgba(209, 240, 255, 0.25);
                                box-shadow: 0px 0px 5px 0px #d1f0ff;
                                border: 2px solid #ffffff;
                            }
                        }

                    }
                }
            }
        }

        .friend {
            width: 1200px;
            margin: 0 auto;

            .module-tit {
                width: 178px;
                padding: 20px 0;
                font-size: 20px;
                font-weight: 900;
                color: #266ddc;
                text-align: center;
                margin: 0 auto;
                background-image: url("../assets/img/list_tit_left.png"), url("../assets/img/list_tit_right.png");
                background-repeat: no-repeat;
                background-position: left center, right center;
                background-size: 14px 14px;
            }

            .imgBanner {
                width: 1200px;
                overflow: hidden;

                .imgShowBox {
                    width: 1000px;
                    transition: margin 500ms;
                    display: flex;
                    flex-wrap: nowrap;
                    margin-left: 0;

                    .imgArr {
                        width: 224px;
                        margin-right: 20px;

                        img {
                            display: block;
                            width: 224px;
                            height: 124px;
                            margin-bottom: 20px;
                            cursor: pointer;
                        }

                        img:last-child {
                            margin-bottom: 0;
                        }
                    }
                }




            }

            .imgs_show {
                display: flex;
            }
        }

        .friendLink {
            width: 1200px;
            margin: 0 auto;

            .module-tit {
                width: 178px;
                padding: 20px 0;
                font-size: 20px;
                font-weight: 900;
                color: #266ddc;
                text-align: center;
                margin: 0 auto;
                background-image: url("../assets/img/list_tit_left.png"), url("../assets/img/list_tit_right.png");
                background-repeat: no-repeat;
                background-position: left center, right center;
                background-size: 14px 14px;
            }

            .links {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                margin-bottom: 18px;
                font-size: 14px;

                a {
                    display: block;
                    margin-right: 10px;
                    padding-right: 10px;
                    color: #333;
                    border-right: 1px solid #333;
                    text-decoration: none;
                    font-size: 14px;
                }

                a:hover {
                    color: #266ddc;
                }

                a:last-child {
                    margin-right: 0;
                    border: none
                }
            }

        }

        .vipService {


            .module-tit {
                width: 178px;
                padding: 20px 0;
                font-size: 20px;
                font-weight: 900;
                color: #266ddc;
                text-align: center;
                margin: 0 auto;
                background-image: url("../assets/img/list_tit_left.png"), url("../assets/img/list_tit_right.png");
                background-repeat: no-repeat;
                background-position: left center, right center;
                background-size: 14px 14px;
            }

            .introduce {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }

            .introduceImg {
                width: 1200px;
                display: block;
                margin: 0 auto;
                margin-bottom: 70px;
                box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
                border-radius: 16px;
            }
        }
    }
</style>