<template>
  <div class='container'>
    <div class="inner">
      <div class="logo" @click="goIndex"></div>
      <div class="infoBox">

        <div class="infoCenter">
          <div class="companyName">{{ footerData.copyrightInformation }}</div>
          <div>电话：{{ footerData.phone }}</div>
          <div>地址：{{ footerData.address }}</div>
          <div>邮编：{{ footerData.postcode }}</div>
          <div>邮箱：{{ footerData.email }}</div>
        </div>
        <div class="infoScan">
          <div class="infoSlide">
            <div @click="goContact">联系我们</div>
            <div @click="goRichText(3)">帮助</div>
            <div @click="goRichText(2)">网站简介</div>
            <div @click="goRichText(1)">隐私声明</div>
          </div>
          <div class="bot">
            <div class="qrcodeBox">
              <img :src="$RESOURCE_URL+contactData.gzhRqCode" alt="">
              <div>公众号二维码</div>
            </div>
            <div class="qrcodeBox">
              <img :src="$RESOURCE_URL+contactData.kfRqCode" alt="">
              <div>客服微信二维码</div>
            </div>
          </div>

        </div>
      </div>

      <div class="beian">
        备案号：
        <a class="beian-a" href="https://beian.miit.gov.cn" rel="noreferrer" target="_blank">
          {{ footerData.recordNo }}
        </a>
      </div>
      <div class="beian">
        经营许可证：
        <a class="beian-a" href="https://tsm.miit.gov.cn/#/home" rel="noreferrer" target="_blank">
          冀B2-20211145
        </a>
      </div>
      <div class="beian">
        <img class="beian-img" src="@/assets/img/gongan.png" alt="">
        <a class="beian-a" href="https://beian.mps.gov.cn/#/query/webSearch?code=13068202000299" rel="noreferrer" target="_blank">
          冀公网安备13068202000299
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {getBasicInfo} from "@/request/webinfo"
import {contactUsList} from "@/request/contact"

export default {
  name: 'HeaderNav',
  props: {},
  data() {
    return {
      footerData: {},
      contactData: {}
    };
  },
  computed: {},
  created() {

  },
  mounted() {
    this.getFooterInfo()
    this.getContact()
  },
  watch: {},
  methods: {
    goIndex() {
      this.$router.push({
        name: "Index"
      })
    },
    goContact() {
      this.$router.push({
        name: "Contact"
      })
    },
    getFooterInfo() {
      getBasicInfo({}).then(res => {
        let _data = res.data[0];
        this.footerData = _data
      })
    },
    getContact() {
      contactUsList({}).then(res => {
        this.contactData = res.data[0]
      })
    },
    goRichText(richTextType) {
      this.$router.push({
        name: "RichText",
        query: {
          richTextType: richTextType
        }
      })
    }
  },
  components: {},
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  z-index: 1;
  height: 436px;
  background-image: url("../assets/img/footerBg.png");
  background-size: cover;
  color: white;

  .inner {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 47px;
    padding-bottom: 20px;

    .logo {
      width: 308px;
      height: 93px;
      margin: 0 auto;
      margin-bottom: 20px;
      background-image: url("../assets/img/PNG3-bai.png");
      background-size: cover;
      cursor: pointer;
    }

    .infoBox {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      // margin-bottom: 21px;


      .infoCenter {
        // width: 308px;
        text-align: left;
        padding-left: 140px;
        margin-top: 1px;
        font-family: PingFangSC, PingFangSC-Medium;

        div {
          font-size: 16px;
          font-weight: 300;
          margin-bottom: 13px;
        }

        .companyName {
          font-size: 20px;
          font-weight: 500;
          text-align: left;
          color: #ffffff;
          line-height: 28px;
          margin-bottom: 23px;
        }
      }

      .infoScan {

        .infoSlide {
          // width: 324px;
          margin-right: 92px;
          margin-bottom: 18px;
          display: flex;
          align-items: center;
          font-family: PingFangSC;

          div {
            font-size: 18px;
            margin-right: 28px;
            cursor: pointer;
            font-weight: 500;
          }

          div:hover {
            text-decoration: underline;
          }
        }

        .bot {
          display: flex;
          align-items: center;
          padding-left: 33px;
          font-weight: 300;

          .qrcodeBox {
            font-size: 18px;
            margin-right: 42px;
            text-align: center;

          }

          img {
            width: 110px;
            height: 110px;
          }
        }

      }
    }

    .beian {
      height: 27px;
      font-size: 14px;
      font-weight: 300;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .beian-img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        margin-bottom: 3px;
      }

      .beian-a {
        color: #ffffff;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }
}
</style>
